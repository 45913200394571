const mutations = {
	setNovasExperiencias: (state, novasExperiencias) => {
		state.novasExperiencias = novasExperiencias;
	},

	setMinhasExperiencias: (state, minhasExperiencias) => {
		state.minhasExperiencias = minhasExperiencias;
	},

	setEncontrosComEspecialista: (state, encontrosComEspecialista) => {
		state.encontrosComEspecialista = encontrosComEspecialista;
	},

	loading: (state, loading) => {
		state.isLoading = loading;
	},

	setInteresseAlto(state, { id, confirmado = false }) {
		state.interesseAlto.id = id;

		state.interesseAlto.confirmado = confirmado;
	},

	setInteresseMuitoAlto(state, { id, confirmado = false }) {
		state.interesseMuitoAlto.id = id;

		state.interesseMuitoAlto.confirmado = confirmado;
	},

	setVisualizouModalExperiencias(state, visualizou) {
		state.visualizouModalExperiencias = visualizou;
	},

	setCodigoIes(state, codigo_ies) {
		state.codigo_ies = codigo_ies;
	},

	setCargaHoraria(state, { total, cursada }) {
		state.cargaHorariaTotal = total;

		state.cargaHorariaCursada = cursada;
	},

	setMatriculadas(state) {
		state.matriculadas = state.minhasExperiencias.filter((experiencia) => experiencia.vinculo && experiencia.vinculo.status === 'matriculado');
	},

	setAprovadas(state) {
		state.aprovadas = state.minhasExperiencias.filter((experiencia) => experiencia.vinculo && experiencia.vinculo.status === 'aprovado');
	},

	setInteresseAguardando(state) {
		state.aguardando = state.minhasExperiencias.filter((experiencia) => experiencia.interesse && experiencia.interesse.status === 'aguardando aprovação');
	},

	addAguardando(state, experiencia) {
		state.aguardando.push(experiencia);
	},

	checkDatasDisponiveis(state) {
		for (let i = 0; i < state.novasExperiencias.length; i++) {
			if (state.novasExperiencias[i].horarios !== null && state.novasExperiencias[i].horarioDisponivel === undefined) {
				for (let j = 0; j < state.matriculadas.length; j++) {
					if (state.matriculadas[i].horarios !== null && state.novasExperiencias[i].horarioDisponivel === undefined) {
						for (let k = 0; k < state.novasExperiencias[i].horarios.length; k++) {
							for (let l = 0; l < state.matriculadas[j].horarios.length; l++) {
								if (state.novasExperiencias[i].horarios[k].data_inicio === state.matriculadas[j].horarios[l].data_inicio) {
									state.novasExperiencias[i].horarioDisponivel = false;
									break;
								}
							}
						}
					}
				}
			}
		}
		for (let i = 0; i < state.novasExperiencias.length; i++) {
			if (state.novasExperiencias[i].horarioDisponivel === undefined) {
				state.novasExperiencias[i].horarioDisponivel = true;
			}
		}
	}


};

export default mutations;
