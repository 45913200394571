const getters = {
	getNovasExperiencias: (state) => {
		return state.novasExperiencias;
	},

	getMinhasExperiencias: (state) => {
		return state.minhasExperiencias;
	},

	getEncontrosComEspecialista: (state) => {
		return state.encontrosComEspecialista;
	},

	isLoading: (state) => {
		return state.isLoading;
	},

	contemDados: (state) => {
		if (state.novasExperiencias.length || state.minhasExperiencias.length || state.encontrosComEspecialista.length) {
			return true;
		}

		return false;
	},

	getInteresseAlto(state) {
		return state.interesseAlto;
	},

	getInteresseMuitoAlto(state) {
		return state.interesseMuitoAlto;
	},

	isModalVisualizado: (state) => {
		return state.visualizouModalExperiencias;
	},

	getCodigoIes: (state) => {
		return state.codigo_ies;
	},

	getCargaHorariaTotal(state) {
		return state.cargaHorariaTotal;
	},

	getCargaHorariaCursada(state) {
		return state.cargaHorariaCursada;
	},

	getCountMatriculadas(state) {
		return state.matriculadas.length;
	},

	getCountAprovadas(state) {
		return state.aprovadas.length;
	},

	getCountAguardando(state) {
		return state.aguardando.length;
	},

	temBloqueio(state) {
		
		if(state.matriculadas.length >= 3) {
			return 'Você atingiu o limite máximo de experiências que pode cursar simultaneamente';
		}
		if(state.aguardando.length + state.matriculadas.length >= 5) {
			return 'Você atingiu o limite máximo de experiências que pode concorrer/cursar simultaneamente';
		}
		if(state.aprovadas.length + state.matriculadas.length >= 9) {
			return 'Você atingiu o limite máximo de experiências que pode concorrer/cursar na plataforma';
		}

		return false;
	},
	
};

export default getters;
