const state = {
	novasExperiencias: [],
	minhasExperiencias: [],
	encontrosComEspecialista: [],
	isLoading: false,
	interesseAlto: {
		id: null,
		confirmado: false,
	},
	interesseMuitoAlto: {
		id: null,
		confirmado: false,
	},
	visualizouModalExperiencias: false,
	codigo_ies: '',
	cargaHorariaTotal: 0,
	cargaHorariaCursada: 0,
	matriculadas: [],
	aprovadas: [],
	aguardando: [],
};

export default state;
